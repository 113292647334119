<template>
  <div>
    <validation-observer ref="suggestion">
      <b-form>
        <b-row>
          <b-col
            cols="12"
          >
            <b-card no-body>
              <b-card-header>
                <h4 class="card-title">{{i18nT(suggestionTypes.find(t => t.value == suggestion.suggestion.Type)? suggestionTypes.find(t => t.value == suggestion.suggestion.Type).text : '')}}</h4>
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="onEdit()">
                    <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onSave()">
                    <span class="align-middle ml-50">{{i18nT(`Save`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="hasRouteId"
                    @click="onDelete()"
                  >
                    <span
                      class="align-middle ml-50"
                      style="color: red"
                    >{{i18nT(`Delete`)}}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <b-card-body v-if="suggestion.suggestion.Label">
                <b-row class="border-bottom pb-2">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="9"
                  >
                    <b-row>
                      <b-col cols="12">
                        <div class="media">
                          <div class="media-aside align-self-start">
                            <div class="media-aside align-self-start">
                              <span
                                class="b-avatar badge-light-primary rounded"
                                style="width: 42px; height: 42px;"
                              >
                                <span class="b-avatar-custom">{{suggestion.suggestion.Rating}}</span>
                                <!---->
                              </span>
                            </div>
                            <div>
                                <feather-icon
                                  class="cursor-pointer text-success"
                                  icon="ChevronUpIcon"
                                  size="18"
                                  @click="onVoteSuggestion(1)"
                                />
                              <br />
                                <feather-icon
                                  class="cursor-pointer text-danger"
                                  icon="ChevronDownIcon"
                                  size="18"
                                  @click="onVoteSuggestion(-1)"
                                />
                            </div>
                          </div>
                          <div class="media-body align-self-center">
                            <h2 class="mb-0">{{ suggestion.suggestion.Label }}</h2>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12">
                        <div class="mt-2 h5" v-html="suggestion.suggestion.Description"/>
                      </b-col>
                      <b-col cols="12">
                        <div>
                          <h6 class="section-label">{{i18nT(`Suggested by`)}}</h6>
                          <div class="detail-field pb-1">
                            <b-avatar
                              variant="light-primary"
                              class="mr-1 mb-1"
                              v-b-tooltip.hover.top="suggestion.suggestion.OwnerObj ? suggestion.suggestion.OwnerObj.Label : 'Anonymous'"
                              :text="suggestion.suggestion.OwnerObj ? suggestion.suggestion.OwnerObj.FirstName[0] + suggestion.suggestion.OwnerObj.LastName[0] : 'AN'"
                              :to="{
                                name: 'view-application',
                                params: { id: suggestion.suggestion.OwnerObj ? suggestion.suggestion.OwnerObj.Id : '' },
                              }"
                            ></b-avatar>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12">
                        <div>
                          <h6 class="section-label">{{i18nT(`Attachments`)}}</h6>
                          <div v-for="document in suggestion.documents" :key="document.Id">
                          <b-link
                            v-if="document.AttachedFile"
                            class="font-weight-bold"
                             :href="document.AttachedFile.DownloadUrl">
                            <span><feather-icon icon="PaperclipIcon" /> {{document.AttachedFile.Label}}</span>  
                          </b-link>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" md="3" class="pr-2 pl-2">
                    <div class="bg-light text-dark p-2">
                      <div class="row">
                        <div class="col-md-6 col-12">
                          <h6 class="section-label">{{i18nT(`Created`)}}</h6>
                          <div class="detail-field pb-1">
                            {{suggestion.suggestion.CreatedAt | date}}
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <h6 class="section-label">{{i18nT(`Updated`)}}</h6>
                          <div class="detail-field pb-1">
                            {{suggestion.suggestion.UpdatedAt | date}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="border-bottom py-1">
                  <b-col cols="12">
                    <h4>{{i18nT(`Comments`)}}</h4>
                    <div
                      v-for="comment in suggestion.comments"
                      :key="comment.Id"
                      class="pt-1 border p-2 mb-2"
                    >
                      <h5>
                        <feather-icon
                          icon="MessageCircleIcon"
                          size="16"
                        />
                        {{comment.UserObj ? comment.UserObj.Label : 'Anonymous'}}
                      </h5>
                      <span class="timestamp text-muted font-small-3">{{i18nT(`Commented on`)}} {{ comment.UpdatedAt | date}}</span>
                      <p
                        class="mt-2 h6"
                        v-html="comment.Comment"
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row class="py-1">
                  <b-col cols="12">
                    <h4>{{i18nT(`Your comment`)}}</h4>
                    <p>{{i18nT(`Ask a question or post an update.`)}}</p>
                    <b-form-group
                      class="mt-2"
                      label-for="suggestionAnonymous"
                    >
                      <b-form-checkbox
                        v-model="anonymous"
                        value="1"
                        unchecked-value="0"
                        switch
                        inline
                        id="suggestionAnonymous"
                        >
                        {{i18nT(`Post in anonymous mode`)}}
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-for="suggestionComment"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="comment"
                        rules="required"
                      >
                        <quill-editor
                          id="suggestionComment"
                          v-model="newComment"
                          :options="snowOption"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <div style="flex: 1" />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-0 mt-md-2 ml-1 mr-1"
                    @click.prevent="onPublishComment"
                  >
                    Publish
                  </b-button>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BCard,
  BFormCheckbox,
  BCardBody,
  BCardHeader,
  BDropdown,
  BDropdownItem,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useAuth from '@/auth/useAuth'

export default {
  components: {
    BButton,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    quillEditor,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BLink,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    const displayExtentControls = useAuth.getCurrentSoftware() != 'ca'

    return {
      snowOption: {
        theme: 'snow',
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean']
          ]
        }
      },
      required,
      tabIndex: 0,
      selectedDocument: null,
      existDocuments: [],
      suggestionTypes: [],
      suggestionDocuments: [],
      suggestion: {
        suggestion: {},
        documents: [],
        votes: [],
        comments: []
      },
      newComment: '',
      anonymous: 0,
      answersData: [''],
      linesCnt: 1,
      possibleAnswers: 1,
      otherOption: false,
      steps: 0,
      stepOptions: [2, 3, 5, 10],
      stepStrings: [null, null, null, null, null, null, null, null, null, null],
      firstStep: '',
      lastStep: '',
      grading: 0,
      document: {},
      Software: [],
      documentGroups: [],
      assigneeFilter: [],
      file: null,
      fileName: null,
      displayExtentControls,
      type: useAuth.getUserType(),
      hasUrl: false,
      hasFile: false,
      originalFileName: null,
      Anonymous: false 
    }
  },
  watch: {
    file: function(val) {
      if (val) {
        this.file = val
        this.fileName = val.name
      } else {
        this.file = null
        this.fileName = ''
      }
    }
  },
  async created() {
    const id = this.$route.params.id
    this.softwares = _.values(useAuth.getSoftwares()).map(software => ({value: software.id, title: `${software.short.toUpperCase()} - ${software.title}`}))

    Promise.all([
      new Promise(res => {
        this.$http.get(`suggestions/suggestionTypes`).then(({data}) => {
          const suggestionTypes = data.data
          for (const property in suggestionTypes) {
            this.suggestionTypes.push({
              value: property,
              text: suggestionTypes[property]
            })
          }
          res()
        })
      }),
    ]).then(() => {
      if (id !== 'new') {
        this.$http.get(`suggestions?id=${id}`).then(({ data }) => {
          const suggestion = data.data
          this.suggestion = suggestion
          console.log('suggestion: ', suggestion)
          
          this.Software = []
          if (this.document.Soft1 == 1) {
            this.Software.push(
              this.softwares.filter(software => software.value === 1)[0]
            )
          }
          if (this.document.Soft2 == 1) {
            this.Software.push(
              this.softwares.filter(software => software.value === 2)[0]
            )
          }
          if (this.type != 'Freelancer') {
            if (this.document.Soft3 == 1) {
              this.Software.push(
                this.softwares.filter(software => software.value === 3)[0]
              )
            }
            if (this.document.Soft4 == 1) {
              this.Software.push(
                this.softwares.filter(software => software.value === 4)[0]
              )
            }
          }
        })
      }
    })
  },
  computed: {
    hasRouteId() {
      return router && router.currentRoute.params.id
    }
  },
  methods: {
    onVoteSuggestion(vote) {
      const formData = new FormData()
      const id = this.$route.params.id

      formData.append('id', id)
      formData.append('vote', vote)
      this.$http
        .post('suggestions/vote', formData)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.successMessage(res),
              icon: 'InfoIcon',
              variant: 'success'
            }
          })

          const id = this.$route.params.id
          this.$http.get(`suggestions?id=${id}`).then(({ data }) => {
            this.suggestion = data.data
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.errorMessage(err),
              icon: 'InfoIcon',
              variant: 'danger'
            }
          })
        })
    },
    onPublishComment() {
      // Prevent modal from closing
      const id = this.$route.params.id
      const formData = new FormData()
      
      formData.append('suggestion_id', id)
      formData.append('Comment', this.newComment)
      formData.append('Anonymous', parseInt(this.anonymous))

      this.$refs.suggestion
        .validate()
        .then(success => {
          if (!success) return
          this.$http
            .post('suggestions/comment', formData)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.successMessage(res),
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              })
              this.$http.get(`suggestions?id=${id}`).then(({ data }) => {
                this.suggestion = data.data
              })
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.errorMessage(err),
                  icon: 'InfoIcon',
                  variant: 'danger'
                }
              })
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    onSave() {
      console.log('Saved!!!')
    },
      onEdit() {
        this.$router.push({name: 'create-edit-suggestion', params: { id: this.suggestion.suggestion.Id },})
      },
    onDelete() {
      this.$swal({
        title: this.i18nT(`Are you sure you want to delete this entry?`),
        text: this.i18nT(`You won't be able to revert this!`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.i18nT(`Confirm`),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
          if (result.value) {
              this.$http
                  .delete(`suggestions?id=${this.suggestion.item.Id}`)
                  .then(() => {
                      this.$swal({
                          icon: 'success',
                          title: this.i18nT(`Deleted!`),
                          text: this.i18nT(`The suggestion was successfully deleted.`),
                          customClass: {
                              confirmButton: 'btn btn-success'
                          }
                      })
                      this.$router.push({name: 'suggestions'})
                  }).catch(err => {
                      this.$swal({
                          icon: 'error',
                          title: 'Error!',
                          text: this.errorMessage(err),
                          customClass: {
                              confirmButton: 'btn btn-success'
                          }
                      })
                  })
          }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss">
.section-title {
  font-size: 11px;
}
.document-name {
  display: inline;
  line-height: 32px;
}
.document-actions-bar {
  float: right;
}
</style>
